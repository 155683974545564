import {FC} from "react";
import styles from "./Header.module.scss"
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import Contacts from "../contacts/Contacts";

function Phone(props: { width: number, height: number }) {
    return null;
}

const Header: FC = () => {
  return (
    <header className={styles.header}>
        <nav>
            <Logo/>
            {/*<Contacts/>*/}
            <div className={'flex flex-col items-end text-md text-gray-600 leading-3 gap-2 font-bold'}>
                <p>г. Тирасполь, ул. К. Маркса, 149</p><p>Центр Инноваций</p>
                <a href="tel:053338124">0(533)38124</a>
                {/*<button className={'btn py-1 text-lg font-bold flex gap-3 items-center '} onClick={()=>setIsShow(!isShow)}>*/}
                {/*  <span className={'text-xl mt-[-5px]'}>@</span>  <span>Обратная связь</span>*/}
                {/*</button>*/}

                {/*<ContactsModal  isVisible={isShow} setIsVisible={setIsShow}/>*/}
                {/*</div>*/}

                {/*<a href="mailto:support@ca.idc.md">support@ca.idc.md</a>*/}
            </div>
            {/*<div className={'flex items-center  text-md  gap-2 font-bold '}>*/}
            {/*    <Phone width={48} height={48}/>*/}

            {/*</div>*/}
        </nav>
        <div className={styles.menu}>
            <nav>
                <Menu/>
            </nav>

        </div>

    </header>
  );
};

export default Header;