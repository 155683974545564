
export const LINKS = {
    CAClientSetupOld:{
        link:'./files/CAClient.zip'
    },
    CAClientSetup:{
        link:'./files/CAClientSetup.exe'
    },
    EnterSafePKIManager:{
        link:"./files/Drivers_for_token_(775665v1).ZIP"
    },
    MicrosoftNETFramework4_7_2:{
        link:"https://dotnet.microsoft.com/en-us/download/dotnet-framework/thank-you/net472-web-installer"
    },
    Timestamp:{
        link:'./files/IDC_Time_Stamp_Authority.cer',
        title:'Сертификат службы штампов времени'
    }
}