import React from 'react';
import Layout from "../../components/layout/layout";
import Title from "../../components/heading/Title";

const Tariffs = () => {
    return (
        <Layout>
            <div className={'flex text-center justify-center mb-10'}>
                <Title>Тарифы</Title>
            </div>
            <div className={'block-shadow text-xl  p-4 bg-white rounded-xl flex flex-col gap-8'}>
                <img src="/images/tariff.jpg" className={'text-center w-96 m-auto'} alt="Тарифы"/>
                <p className={'p-4'}>Выдача сертификата открытого ключа электронной подписи сроком действия 1 год:
                    <ul  className={'list-disc pt-2 pl-8'}>
                    <li> <span className={'w-60 inline-block'}>для физических лиц  </span>	 	49,00 руб. ПМР</li>
                    <li><span className={'w-60 inline-block'}>для юридических лиц 	 </span> 49,00 руб. ПМР</li>
                </ul>
            </p>

            </div>
        </Layout>
);
};

export default Tariffs;