import React, {FormEvent, useState} from 'react';
import Layout from "../../components/layout/layout";
import {api, ICertificateInfo} from "../../api/service";
import styles from "../../components/certificate/certificate.module.scss";
import Spin from "../../components/spin/spin";
import Faq from "../../components/faq/Faq";

const Certificate = () => {
    const [response, setResponse] = useState<ICertificateInfo | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const exportDataToFile = async (serial: string) => {
        try {
            await fetch(linkDownload(serial))
                .then((response) => {
                    if ((response.ok === true) && (response.status === 200)) {
                        return response.blob();
                    }
                })
                .then(data => {
                    // @ts-ignore
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${url.split('/')[3]}.cer`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        } catch (error) {
            console.log(error);
        }
    };


    const handler = () => {

        setResponse(null)
    }

    const submitForm = (event: FormEvent) => {
        setIsLoading(true)
        event.preventDefault()
        // @ts-ignore
        const number = event.target[0].value.trim().split(' ').join('')
        api.getCertificateInfo(number)
            .then(setResponse)
            .then(() => setIsLoading(false))
            .catch((e) => {
                console.log(e)
                setIsLoading(false)
            })

    }

    const linkDownload = (serial: string) => `https://ca.idc.md/download.php?serial=${serial}`



    return (
        <Layout>

            <div className={styles.certificate}>
                <h4 className={'text-2xl mt-4 text-center'}>Введите идентификатор сертификата</h4>


                    <div className={response ? styles.veryActive : styles.active}>
                        <div className={styles.content}>
                            <form className={'flex gap-4'} onSubmit={submitForm}>
                                <input type="text" placeholder={'идентификатор сертификата'}
                                       className={'w-full'} required/>
                                <button className={' bg-blue-700 text-white px-5 rounded-xl'} type='submit'> проверить
                                </button>

                            </form>


                            {isLoading ? <Spin/> : response ?
                                response.certificateInfo === null ?
                                    <p className={'px-5'}>Cертификат не найден</p> :
                                    <div className={styles.result}>
                                        <div>Владелец: {response.certificateInfo.commonName}</div>
                                        <div>Серийный номер: {response.certificateInfo.serialNumber}</div>
                                        <div>Дата
                                            выдачи: {new Date(response.certificateInfo.notBefore).toLocaleDateString()}</div>
                                        <div>Дата
                                            окончания: {new Date(response.certificateInfo.notAfter).toLocaleDateString()}</div>
                                        <div>Издатель: {response.certificateInfo.issuer}</div>
                                        <div>Статус: {response.certificateInfo.certificateStatusText} </div>
                                        <button className='mt-3 py-3 bg-blue-700 text-white px-5 rounded-xl'
                                                onClick={() => exportDataToFile(response.certificateInfo.serialNumber)}>скачать
                                            сертификат
                                        </button>
                                    </div>
                                : ''
                            }
                            <h4 className={'text-xl mt-8 text-center'}>Где взять идентификатор сертификата?</h4>
                            <div className={'flex justify-center '}>
                                <img src={'/images/sert.jpg'} className={''} alt={'ca.idc.md'}/>
                            </div>


                        </div>
                    </div>
            </div>
        </Layout>
);
};

export default Certificate;