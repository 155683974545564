import React from 'react';
import Title from "../../../components/heading/Title";
import Layout from "../../../components/layout/layout";
import styles from "../certificates.module.scss";
import {LINKS} from "../certificates";

const Root = () => {

return (
    <Layout>
        <div className={"flex justify-center items-center"}>
            <Title>Сертификаты</Title>
        </div>

        <div className="flex justify-center  md:flex-nowrap flex-wrap gap-10">


            <a href={LINKS.ROOT_PMR_CERTIFICATE.link} className={styles.certificate} download
               rel="noopener">
                {/*<span className={'text-xs text-gray-500'}>скачать</span>*/}
                <div className={styles.wrapper}>
                    <span>скачать</span>
                    <img src="/images/111.png" alt={LINKS.ROOT_PMR_CERTIFICATE.title}/>
                </div>

                <span className={'font-bold'}>{LINKS.ROOT_PMR_CERTIFICATE.title}</span>
                <div className={"absolute top-20 left-[82%] z-40"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24"
                         fill="none">
                        <path
                            d="M16 6.58569L21.4142 11.9999L16 17.4141L14.5858 15.9999L17.5858 12.9999L3 12.9999L3 10.9999L17.5858 10.9999L14.5858 7.99991L16 6.58569Z"
                            fill="#145dea"/>
                    </svg>
                </div>
            </a>


            <a href={LINKS.ROOT_CERTIFICATE.link} className={styles.certificate}>
                {/*<span className={'text-xs text-gray-500'}>скачать</span>*/}
                <div className={styles.wrapper}>
                    <span>скачать</span>
                    <img src="/images/222.png" alt={LINKS.ROOT_CERTIFICATE.title}/>
                </div>
                <span className={'font-bold'}>{LINKS.ROOT_CERTIFICATE.title}</span>
                <div className={"absolute top-20 left-[82%] z-40"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24"
                         fill="none">
                        <path
                            d="M16 6.58569L21.4142 11.9999L16 17.4141L14.5858 15.9999L17.5858 12.9999L3 12.9999L3 10.9999L17.5858 10.9999L14.5858 7.99991L16 6.58569Z"
                            fill="#145dea"/>
                    </svg>
                </div>
            </a>


            <a href={LINKS.ISSUER_CERTIFICATE.link} className={styles.certificate}>
                {/*<span className={'text-xs text-gray-500'}>скачать</span>*/}
                <div className={styles.wrapper}>
                    <span>скачать</span>
                    <img src="/images/333.png" alt={LINKS.ISSUER_CERTIFICATE.title}/>
                </div>
                <span className={'font-bold'}>{LINKS.ISSUER_CERTIFICATE.title}</span>

            </a>


        </div>
        <p className='text-xl '>
            <a href={LINKS.P7B.link} className={'underline underline-offset-4 text-accent'}>  Cкачать одним файлом </a>
        </p>

    </Layout>
)
}
;

export default Root;