import React, { useState } from 'react';
import Layout from "../../components/layout/layout";
import { api } from "../../api/service";
import styles from "./verify.module.scss";
import { useForm } from "react-hook-form";
import Title from "../../components/heading/Title";
import Spin from "../../components/spin/spin";

function downloadBase64File(base64String: string, fileName: string) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
}

const Verify = () => {
    const { register, handleSubmit, formState: { errors, isValid, isLoading, isSubmitted }, reset } = useForm();
    const [error, setError] = useState('');
    const [isError, setIsError] = useState(false);
    const [isReset, setIsReset] = useState(true);
    const [file, setFile] = useState({ docBody: '', docName: '' });
    const [selectedFileName, setSelectedFileName] = useState('');
    const [fileDownloaded, setFileDownloaded] = useState(false);

    const onSubmit = (data: any) => {
        const file = data.file[0];
        const formData = new FormData();
        formData.append("file", file);

        api.postPDF(formData)
            .then((res) => {
                setIsReset(false);
                setFile(res);
                setFileDownloaded(false); // Reset file downloaded state
                return res;
            })
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                setIsReset(false);
                const xMessage = e.response.headers['x-message'] ? decodeURIComponent(escape(e.response.headers['x-message'])) : '';
                setIsError(true);
                setError(xMessage);
            });
    };

    const onReset = (e: any) => {
        e.preventDefault();
        reset();
        setIsError(false);
        setError('');
        setFile({ docBody: '', docName: '' });
        setSelectedFileName('');
        setFileDownloaded(false); // Reset file downloaded state
    };

    const handleFileChange = (e: any) => {
        const file = e.target.value;
        if (file) {
            setSelectedFileName(file);
        }
    };

    const handleDownload = (e:any) => {
        e.preventDefault()
        downloadBase64File(file.docBody, file.docName);
        setFileDownloaded(true);
        onReset(0);
    };

    return (
        <Layout>
            <div className={'flex text-center justify-center mb-10'}>
                <Title>Проверка электронной подписи</Title>
            </div>

            <div className={'flex flex-col gap-5'}>
                <div className={'block-shadow text-xl p-4 bg-white rounded-xl'}> Проверьте
                    действительность электронной подписи и убедитесь в том, что подписанный с помощью нее документ
                    имеет юридическую силу.
                </div>

                <div className="flex gap-5">
                    <div className="flex-1 ">
                        <div className={'block-shadow text-xl p-4 bg-white rounded-xl flex flex-col gap-8'}>
                            <form className={'flex justify-center flex-col gap-8 items-center'}
                                  onSubmit={handleSubmit(onSubmit)}>
                                {file.docName.length > 0 ? (
                                    <button type='reset' className='btn py-2 text-md font-bold flex gap-3 items-center'
                                            onClick={handleDownload}>
                                        {file.docName}
                                    </button>
                                ) : isSubmitted ? isError ? <div className={'error'}>Ошибка!</div> : (
                                    <p className="text-3xl font-bold text-accent">Проверка<Spin/></p>
                                ) : null}
                                {isSubmitted ? (
                                    <div className={'flex justify-center flex-col gap-4 items-center'}>
                                        <img src="/images/1.png" className={'text-center w-40 m-auto'} alt="Тарифы"/>
                                        <button className='btn py-2 text-md font-bold flex gap-3 items-center'
                                                onClick={onReset}>
                                            Отправить
                                        </button>
                                    </div>
                                ) : (
                                    <div className={'flex justify-center gap-4 flex-col items-center rounded p-2'}>
                                        <label className={styles.customFileUpload} onChange={handleFileChange}>
                                            <img src="/images/1.png" className={'text-center w-40 m-auto'}
                                                 alt="Тарифы"/>
                                            <div>{selectedFileName}</div>
                                            <div className='btn w-60 m-auto py-2 text-md font-bold text-center mt-4 '>
                                                {selectedFileName.length === 0 ? "Выбрать файл" : "Изменить файл"}
                                            </div>
                                            <input type="file" {...register("file")} className={''}/>
                                        </label>
                                        <button disabled={selectedFileName.length === 0}
                                                className='btn py-2 text-md font-bold flex gap-3 items-center'
                                                type='submit'>
                                            Отправить
                                        </button>
                                    </div>
                                )}
                            </form>
                            {isError ? (
                                <div className={'flex justify-center flex-col gap-4 items-center'}>
                                    <div>{error}</div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="flex-1 ">
                        <div className="block-shadow text-xl p-4 bg-white rounded-xl">
                            Поддерживаемые форматы электронных документов:
                            <ul className={'list-disc list-inside'}>
                                <li>pdf</li>
                                <li>xml:
                                    <ul className={'list pl-10 '} style={{listStyle: 'circle'}}>
                                        <li>E-doc (коммерческие электронные документы)</li>
                                        <li>G-doc (электронные документы межведомственного электронного
                                            документооборота)
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className={'block-shadow text-xl p-4 bg-white rounded-xl'}>
                    В результате проверки Вы получаете <strong>Протокол проверки электронной подписи</strong> в
                    формате pdf подписанный усиленной квалифицированной электронной подписью удостоверяющего центра.
                    Протокол имеет юридическую силу и позволяет:
                    <ul className={'list-disc list-inside'}>
                        <li>убедиться в подлинности документа.</li>
                        <li>удостоверится в том, что документ был действительно подписан.</li>
                        <li>удостовериться в том, что после подписания в документ не вносили изменения.</li>
                        <li>удостовериться в том, что документ подписало правомерное подписывающее лицо (подтверждение
                            авторства подписи).
                        </li>

                        <li>удостовериться в том, что на момент подписания документа сертификат электронной подписи был
                            действителен: не истек и не был отозван, приостановлен.
                        </li>
                        <li>убедиться в том, что электронная подпись является квалифицированной, т.е. сертификат этой
                            подписи выдан <a href="http://pki.gospmr.org/subca.asp"
                                             className={'underline text-blue-700'} target='_blank'>аккредитованным
                                удостоверяющим центром</a>.
                        </li>
                    </ul>
                </div>
                <div className={'block-shadow text-xl p-4 bg-white rounded-xl'}>

                    Чтобы проверить юридическую значимость протокола проверки электронной подписи предварительно
                    настройте программное обеспечение для работы с pdf-документами по <a
                    href='/docs/settings-for-pdf.pdf' className={'underline text-blue-700'}>инструкции</a>.

                    {/*<h5 className={'font-bold mb-4 '}>Инструкция по настройке программного обеспечения для просмотра электронных документов в формате PDF подписанных электронной подписью:</h5>*/}
                    {/*<ul className={'list-disc list-inside text-lg'}>*/}
                    {/*    <li><strong>Adobe Acrobat Reader/Pro.</strong> В главном меню программы выберите пункт “Установки”. В открывшемся окне “Установки” в разделе “Категории” выберите пункт “Подписи”. В разделе “Проверка” напротив надписи: “Настройки способа и времени подтверждения подписей” нажмите кнопку “Подробнее…”. В открывшемся окне “Установки для подтверждения подписи” в разделе “Интеграция с Windows” установите опции “Подтверждение подписей” и “Подтверждение сертифицированных документов”.</li>*/}
                    {/*    <li><strong>Foxit PDF Reader/Editor.</strong> В меню “Файл” программы выберите пункт “Установки”. В открывшемся окне “Установки” выберите в списке пункт “Подпись”. В разделе “Подписание и проверка” под надписью: “Задать способ и время проверки подписей” нажмите кнопку “Изменить параметры”. В открывшемся окне “Настройки проверки подписи” в разделе “Службы интеграции Windows” установите опции “Проверка подписей” и “Проверка сертифицированных документов”.</li>*/}

                    {/*</ul>*/}

                </div>
                <div className={'block-shadow text-xl p-4 bg-white rounded-xl'}>
                    <h5 className={'font-bold mb-4 '}>Роль штампа времени при проверке действительности электронной подписи</h5>
                    <p>При использовании электронной подписи, содержащей <a href="/timestamp"  className={'underline text-blue-700'}>штамп времени</a>, проверка ее действительности осуществляется на дату и время подписания электронного документа. В случае отсутствия <a href="/timestamp"  className={'underline text-blue-700'}>штампа
                        времени</a> проверка действительности электронной подписи осуществляется на дату и временя проведения такой проверки и в случае истечения сроков действия электронной подписи результат проверки ее действительности будет отрицательным.</p>
                </div>
            </div>


        </Layout>
);
};

export default Verify;
