import React from "react";
import {RouteNames} from "../../common/variables/RouteNames";
import Home from "../../pages/home/Home";
import Documents from "../../pages/documents/Documents";
import Downloads from "../../pages/downloads/Downloads";
import Page404 from "../../pages/page404/page404";
import Contacts from "../../pages/contacts/Contacts";
import Certificates from "../../pages/certificates/certificates";
import Police from "../../pages/police/police";
import Revokes from "../../pages/certificates/revokes/revokes";
import Root from "../../pages/certificates/root/root";
import Timestamp from "../../pages/timestamp/timestamp";
import Certificate from "../../pages/certificate/certificate";
import Tariffs from "../../pages/tariffs/tariffs";
import Win from "../../pages/win/win";
import Verify from "../../pages/verify/verify";


export interface IRoute {
    path: string;
    element: React.ReactElement;
}

export const publicRoutes: IRoute[] = [
    {path: RouteNames.HOME, element: <Home/>},
    {path: RouteNames.CONTACTS, element: <Contacts/>},
    {path: RouteNames.DOCUMENTS, element: <Documents/>},
    {path: RouteNames.DOWNLOADS, element: <Downloads/>},
    {path: RouteNames.CERTIFICATES, element: <Certificates/>},
    {path: RouteNames.CERTIFICATES_ROOT, element: <Root/>},
    {path: RouteNames.CERTIFICATES_REVOKED, element: <Revokes/>},
    {path: RouteNames.POLICES, element: <Police/>},
    {path: RouteNames.CERTIFICATE, element: <Certificate/>},
    {path: RouteNames.TIME, element: <Timestamp/>},
    {path: RouteNames.TARIFFS, element: <Tariffs/>},
    {path: RouteNames.PDF, element: <Verify/>},
    {path: RouteNames.WIN, element: <Win/>},
    {path: '*', element:<Page404/> }
];
