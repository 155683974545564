interface IDocuments {
  title: string
  link: string
}

export const documents: IDocuments[] = [
  {
    title: 'Регламент Удостоверяющего Центра',
    link: '/docs/reglament_ca_idc_2.0.pdf'
  },
  {
    title: 'Инструкция по получению сертификата открытого ключа для юридические лиц',
    link: '/docs/instrukciya_po_polucheniyu_ser1.pdf'
  },
  {
    title: 'Инструкция по получению сертификата открытого ключа для физических лиц',
    link: '/docs/instrukciya_po_polucheniyu_ser.pdf'
  },
  {
    title: 'Инструкция пользователя для программы IDC Управление ключами',
    link: '/docs/instrukciya_polzovatelya_dlya.pdf'
  },
  {
    title: 'Инструкция по настройке программ для проверки электронной подписи в PDF-документах',
    link: '/docs/settings-for-pdf.pdf'
  },

  {
    title: 'Руководство пользователя «Токен ePass2003»',
    link: '/docs/rukovodstvo_polzovatelya_token_ePass2003.pdf'
  },
  {
    title: 'Положение о защите персональных данных',
    link: 'https://idc.md/storage/app/media/docs/personal-data-proctect/About-personal-data-protect.pdf'
  },
  {
    title: 'Политика обработки персональных данных',
    link: 'https://idc.md/storage/app/media/docs/personal-data-proctect/Personal-data-politics.pdf'
  },
  {
    title: 'СОГЛАСИЕ на обработку персональных данных ',
    link: '/docs/agreement.pdf'
  },


]


// const exludesDoc: IDocuments[] = [
//   {
//     title: 'Инструкция по организации парольной защиты',
//     link: '/docs/instrukciya_po_organizacii_par.pdf'
//   },
//   {
//     title: 'Инструкция по организации антивирусной защиты',
//     link: '/docs/instrukciya_po_organizacii_ant.pdf'
//   },
//   {
//     title: 'Инструкция оператора для программы IDC: CA менеджер',
//     link: '/docs/instrukciya_operatora_dlya_pro.pdf'
//   },
//   {
//     title: 'Инструкция оператора для программы IDC: CA менеджер',
//     link: '/docs/instrukciya_operatora_dlya_pro.pdf'
//   },
//
// ]