import React from 'react';
import Layout from "../../components/layout/layout";
import Title from "../../components/heading/Title";
import styles from "../downloads/downloads.module.scss";
import {ReactComponent as DownloadIcon} from "../../assets/images/download.svg";
import {LINKS} from "../../common/variables/links";

interface iData  { title: string, description?: string ; }

const TimestampData: iData[]= [
    {
        title: 'Фиксирует автора и время создания электронной подписи под документом.',
        description: " Это необходимо для доказательства юридической значимости. Данная возможность может быть использована при организации юридически значимого документооборота."
    },
    {
        title: 'Фиксирует время создания электронного документа. ',
        description: "Подтверждает существование определённого электронного документа на момент формирования штампа времени.  Данная возможность может быть использована в области авторского права."
    },
    {
        title: 'Позволяет продлить срок действия электронной подписи в электронном документе.',

        description:"Штамп времени в подписанном документе удостоверяет факт того, что электронная подпись под документом была сформирована до момента окончания срока действия сертификата автора подписи или его отзыва. Штамп времени продлевает легитимность электронной подписи автора до момента окончания срока действия сертификата службы штампов времени, которым подписан штамп в документе.  Позволяет осуществлять проверку подписи даже после того, как сертификат автора подписи перестанет быть действительным."
    },
    {
        title: 'Организует долгосрочное архивное хранение электронных документов.',

        description: "Штамп времени продлевает срок действия электронной подписи под документом до момента окончания срока действия сертификата службы штампов времени. Если до момента истечения срока действия сертификата службы штампов времени получить новый штамп времени, подписанный уже новым сертификатом службы штампов времени, то такой штамп продлит срок действия электронной подписи уже до момента окончания срока действия нового сертификата службы штампов времени. Таким образом, повторяя процедуру получения штампа времени каждый раз, когда у службы штампов времени меняется сертификат подписи, можно с помощью получившейся цепочки штампов времени продлевать срок действия электронной подписи под документом на десятки лет и при этом иметь возможность в любой момент проверить ее."
    }
]
const Timestamp = () => {
    return (
        <Layout>
            <div>
                <div className={'flex text-center justify-center mb-10'}>
                    <Title>Служба штампов времени “IDC Time Stamp Authority”</Title>
                </div>
                <div className={'block-shadow my-5 text-lg p-4 bg-white rounded-xl flex flex-col g'}>

                    <h3> Штамп времени:</h3>
                    <ul className={'list-disc text-lg pl-6'}>
                        {TimestampData.map(item =>(
                            <li className={'my-2'}><h4>{item.title}</h4>
                                <p className={'text-sm text-gray-500'}>{item.description}</p>
                            </li>)
                        )}
                    </ul>

                </div>
                <div className={'block-shadow  text-lg p-4 bg-white rounded-xl flex flex-col gap-8'}>



                    <p>
                        Настроить параметры запроса на получение штампов времени Вы можете в процессе электронной
                        подписи
                        документа:

                        <ul className={'list-disc pl-8 pt-2'}>
                            <li>Адрес службы штампов времени: http://ca.idc.md/tsa?workerName=IDC_TimeStampSigner
                        </li>
                        <li>Тип аутентификации: Анонимный доступ</li>
                        <li>Допустимые алгоритмы хэш-функции от документа, на который запрашивается штамп: SHA256,
                            SHA512.
                            Значение по умолчанию: SHA256
                        </li>
                        <li>Объектный идентификатор политики (OID политики): 1.3.6.1.4.1.58637.1.2 (необязательно).
                            Значение
                            по умолчанию: 1.3.6.1.4.1.58637.1.2.
                        </li>
                        <li>“Nonce”: случайное число, идентифицирующее данную транзакцию протокола TSP
                            (необязательно).
                        </li>
                        <li>“Запросить сертификат службы штампов времени”: определяет, нужно ли включать сертификат
                            службы в
                            штамп времени.
                        </li>
                    </ul>
                    </p>
                </div>
                    <div className={'block-shadow my-5 text-lg p-4 bg-white rounded-xl flex flex-col gap-8'}>
                    <p className={''}> Характеристики штампа времени, определяемые политикой службы:
                        <ul className={'list-disc pl-8 pt-2'}>
                            <li>Точность времени в штампе составляет 1 секунду.</li>
                            <li>В штамп включается имя службы.</li>
                        <li>В штамп включается дополнительное значение хэш-функции от сертификата подписи службы штампа
                            времени по алгоритму хэширования SHA256
                        </li>
                    </ul>
                    </p>
                </div>

            </div>

            <a href={LINKS.Timestamp.link} className={styles.link}>
                <DownloadIcon className={styles.icon}/>
                <span>      {LINKS.Timestamp.title}</span>
            </a>

        </Layout>
    );
};

export default Timestamp;