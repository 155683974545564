import {FC} from 'react';
import styles from './spin.module.scss'

const Spin: FC = () => {
  return (
    <div className='flex justify-center'>
      <span className={styles.loader}></span>
    </div>
   
  );
};

export default Spin;