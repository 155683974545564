import React from 'react';
import Faq from "../../components/faq/Faq";

const How = () => {
    return (
        <>
            <h3 className={'subtitle '}>Как получить <span className={'text-accent'}>сертификат электронной подписи</span> ?</h3>
            <div className={'bg-gray-100 mt-8 block-shadow p-8 pt-4 rounded-2xl'}>
                <div className={'flex-col flex-center-between  lg:flex-row   '}>
                    <p className={'text-main w-4/5'}>Для получения сертификата открытого
                        ключа электронной подписи, будущий
                        владелец сертификата должен лично явиться к представителю
                        Удостоверяющего центра для идентификации его личности и полномочий и
                        пройти процедуру регистрации в строгом соответствии с{" "}
                        <a href="/docs/reglament_ca_idc_2.0.pdf"
                           target='_blank'
                           className={' font-bold border-b-2 hover:border-blue-700 transition-all py-1 px-1 '}>Регламентом
                            Удостоверяющего центра.</a>
                    </p>
                    <div className={' md:pt-0 '}>
                        <img src={'./images/arrow_down.png'} className={'w-60 max-w-xs m-auto'} alt={'ca.idc.md'}/></div>
                </div>


                <div className="flex flex-col mt-4 gap-4">
                    <p className={'text-main '}>Для получения сертификата
                    открытого ключа электронной подписи
                    понадобятся
                    следующие документы и сведения:</p>
                    <Faq title={'Для юридического лица'}>
                        <ul className={'list-disc text-lg pl-6'}>
                            <li>  <a href="https://uslugi.gospmr.org/?view=usluga&id=108"
                                     target='_blank'
                                     className={' font-bold border-b-2 hover:border-blue-700 transition-all py-1  '}>выписка
                                из ГРЮЛ</a>, выданная не позднее, чем за 10 календарных дней до даты обращения в
                                Удостоверяющий центр
                            </li>

                            <li>паспорт лица (удостоверение личности), обладающего правом на представление интересов
                                юридического лица без доверенности
                            </li>

                            <li> справка из обслуживающего банка об открытии счетов</li>
                           <p className={'mt-4'}> В случае обращения за регистрацией иного лица, не указанного в выписке из ГРЮЛ,
                               дополнительно предоставляется:</p>
                            <li> паспорт (удостоверение личности) представителя заявителя</li>
                            <li> доверенность представителя</li>
                            При обращении представителя организации паспорт руководителя юридического лица не предоставляется.
                        </ul>
                    </Faq>
                    <Faq title={' Для органов государственной власти и управления'}>
                        <ul className={'list-disc text-lg pl-6'}>


                            <li>паспорт лица (удостоверение личности), обладающего правом на представление интересов
                                органов
                                государственной власти и управления без доверенности
                            </li>
                            <li>заверенная копия документа о назначении на должность руководителя органа государственной
                                власти
                                и управления
                            </li>
                            <li>заверенная копия сертификата о присвоении фискального кода (иной документ,
                                свидетельствующий о
                                присвоении фискального кода)
                            </li>
                            <li>справка из обслуживающего банка об открытии счетов</li>
                           <p className={'mt-4'}> В случае обращения за регистрацией иного лица, не являющегося руководителем органа
                               государственной
                               власти и управления, дополнительно предоставляются:</p>
                            <li>паспорт (удостоверение личности) представителя заявителя</li>
                            <li>доверенность представителя</li>

                            При обращении представителя организации паспорт руководителя органа государственной власти и управления не предоставляется.

                        </ul>
                    </Faq>


                    <Faq title={'Для физического лица'}>
                        <ul className={'list-disc text-lg pl-6'}>
                            <li>паспорт (удостоверение личности)</li>
                            <li>свидетельство о регистрации по месту жительства (при отсутствии штампа в паспорте)</li>
                            <li>сведения о наличии счета в банке ПМР (номер счета)  -  по желанию Клиента.</li>
                        </ul>
                    </Faq>

                    <Faq title={' Для специального субъекта'}>
                        <ul className={'list-disc text-lg pl-6'}>
                            <li> паспорт (удостоверение личности)</li>
                            <li> свидетельство о регистрации по месту жительства (при отсутствии штампа в паспорте)</li>
                            <li> удостоверение (иной документ, свидетельствующий о специальном статусе)</li>
                            <li> сведения о наличии счета в банке ПМР (номер счета)  -  по желанию Клиента.</li>
                        </ul>
                    </Faq>
                </div>
            </div>
        </>
    )
        ;
};

export default How;