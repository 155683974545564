import React from 'react';
import styles from "../certificates.module.scss";
import Title from "../../../components/heading/Title";
import Layout from "../../../components/layout/layout";
import {LINKS} from "../certificates";


const Revokes = () => {
    return (
        <Layout>
            <div className={"flex justify-center items-center"}>
                <Title>Списки отзывов</Title>
            </div>
            <div>
                <div className="flex  md:flex-nowrap flex-wrap  justify-center mt-10 gap-10">
                    <a href={LINKS.ROOT_CRL.link} className={styles.certificate_crl}>
                        <span className={'text-xs text-gray-500'}>{LINKS.ROOT_CRL.description}</span>
                        <img src="/images/222revoke.png" alt={LINKS.ROOT_CERTIFICATE.title}/>
                        <span className={'font-bold'}>{LINKS.ROOT_CRL.title}</span>

                    </a>
                    <a href={LINKS.ISSUER_CRL.link} className={styles.certificate_crl}>
                        <span className={'text-xs text-gray-500'}>{LINKS.ISSUER_CRL.description}</span>
                        <img src="/images/333revoke.png" alt={LINKS.ISSUER_CRL.title}/>
                        <span className={'font-bold'}>{LINKS.ISSUER_CRL.title}</span>

                    </a>
                    <a href={LINKS.DELTA_CRL.link} className={styles.certificate_crl}>
                        <span className={'text-xs text-gray-500'}>{LINKS.DELTA_CRL.description}</span>
                        <img src="/images/333revoke.png" alt={LINKS.DELTA_CRL.title}/>
                        <span className={'font-bold'}>{LINKS.DELTA_CRL.title}</span>
                    </a>
                </div>
            </div>
        </Layout>
    );
};

export default Revokes;