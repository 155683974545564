import React from 'react';
import Layout from "../../components/layout/layout";
import Title from "../../components/heading/Title";

import {Link} from "react-router-dom";

import {RouteNames} from "../../common/variables/RouteNames";


const Police = () => {
    return (
        <Layout>
            <div>
                <div className={'flex justify-center mb-10'}>
                    <Title>Политики сертификата</Title>
                </div>
                {/*<SecondaryBlock image={'/images/1.png'} >*/}
                <div className={'block-shadow text-main text-xl p-4 bg-white rounded-xl flex flex-col gap-8'}>


                    <p className={'text-xl'}> Корневой центр сертификации удостоверяющего центра CЗАО
                        «Интерднестрком» является
                        подчиненным (промежуточным) центром сертификации по отношению
                        к <a href="http://pki.gospmr.org/kornUC.asp" className={'text-blue-700  underline-offset-4 underline hover:underline-offset-8 transition-all'}>корневому удостоверяющему центру</a>{" "}
                        Приднестровской Молдавской Республики.
                    </p>
                    <p className={'text-xl'}> Корневой центр сертификации выдает сертификаты только
                       подчиненному
                        (издающему) центру сертификации удостоверяющего центра CЗАО
                        «Интерднестрком».</p>


                    <p className={'text-xl'}> Подчинённый (издающий) центр сертификации  выдаёт сертификаты только конечным
                        пользователям удостоверяющего центра. Правила использования полученного пользователем
                        сертификата, процедуры обновления или аннулирования сертификата и действия в нестандартных
                        ситуациях (например, при утере или краже сертификата с ключами) описаны в <a
                            className={'text-blue-700    underline-offset-4 underline hover:underline-offset-8 transition-all'}
                            target='_blank' href="/docs/reglament_ca_idc_2.0.pdf">Регламенте
                            Удостоверяющего центра</a>.</p>
                    <p className={'text-xl'}> <Link to={RouteNames.CERTIFICATES_ROOT}>
                        <a             className={'text-blue-700  underline-offset-4 underline hover:underline-offset-8 transition-all'}>Сертификаты</a></Link> корневого и издающего центров сертификации удостоверяющего
                        центра
                        CЗАО
                        «Интерднестрком» имеют одинаковый срок действия - 10 лет.</p>
                </div>
                {/*</SecondaryBlock>*/}
                {/*<div className={'text-xl flex flex-col gap-4'}>*/}
                {/*    <p> Корневой центр сертификации «IDC Root CA» удостоверяющего центра CЗАО «Интерднестрком» является*/}
                {/*        подчиненным (промежуточным) центром сертификации по отношению к корневому удостоверяющему центру*/}
                {/*        Приднестровской Молдавской Республики <a href="http://pki.gospmr.org/kornUC.asp" className={'text-blue-700  underline-offset-4 underline hover:underline-offset-8 transition-all'}>«Root PMR CA»</a>.</p>*/}
                {/*    <p> Корневой центр сертификации «IDC Root CA» выдает сертификаты только подчиненному*/}
                {/*        (промежуточному) центру сертификации «IDC Issuer CA» удостоверяющего центра CЗАО*/}
                {/*        «Интерднестрком».</p>*/}
                {/*    <p> Подчинённый (издающий) центр сертификации «IDC Issuer CA» выдаёт сертификаты только конечным*/}
                {/*        пользователям удостоверяющего центра. Правила использования полученного пользователем*/}
                {/*        сертификата, процедуры обновления или аннулирования сертификата и действия в нестандартных*/}
                {/*        ситуациях (например, при утере или краже сертификата с ключами) описаны в Регламенте*/}
                {/*        Удостоверяющего центра.</p>*/}
                {/*    <p> Сертификаты корневого и издающего центров сертификации удостоверяющего центра CЗАО*/}
                {/*        «Интерднестрком» имеют одинаковый срок действия - 10 лет.</p>*/}
                {/*</div>*/}

            </div>
        </Layout>
    );
};

export default Police;