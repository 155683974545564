import {FC} from 'react';
import Layout from "../../components/layout/layout";
import Title from "../../components/heading/Title";
import {ReactComponent as DownloadIcon} from "../../assets/images/download.svg";
import styles from './downloads.module.scss'
import {LINKS} from "../../common/variables/links";


const Downloads: FC = () => {
    return (
        <Layout>
          <div className={"flex justify-center items-center"}>
            <Title>Программы</Title>
          </div>
          <div>
            <p>
              <b>IDC: Управление ключами </b> <br/>
                Позволяет подавать в УЦ запросы на получение сертификатов открытых ключей электронной подписи. Позволяет
                загружать выданные сертификаты, просматривать детальную информацию по ним и, при необходимости, отзывать
                (аннулировать) сертификаты, приостанавливать/возобновлять действие сертификатов.
                <a href={LINKS.CAClientSetup.link} className={styles.link}>
                <DownloadIcon className={styles.icon}/>
                <span>IDC: Управление ключами</span>
              </a>
            </p>
              <p>
                  <b>EnterSafe PKI Manager</b> <br/>
                  Позволяет провести первичную инициализацию ключевого носителя информации (токена), присвоить ключевому
                  носителю удобное имя и сменить ПИН-код пользователя.
                  Также позволяет проводить основные операции с сертификатами, находящимися на ключевом носителе.
                  <a href={LINKS.EnterSafePKIManager.link} className={styles.link}>
                      <DownloadIcon className={styles.icon}/>
                      <span>EnterSafe PKI Manager</span>
                  </a>
              </p>
              <p>
                  <b>Microsoft.NET Framework версии 4.7.2 </b><br/>
                  Пакет программных библиотек компании Microsoft, необходимый для функционирования пользовательских
                  программ.
                  <a href={LINKS.MicrosoftNETFramework4_7_2.link}
                     className={styles.link}>
                      <DownloadIcon className={styles.icon}/>
                      <span>Microsoft.NET Framework версии 4.7.2</span>
                  </a>
              </p>


          </div>
        </Layout>
    );
};

export default Downloads;