import {FC} from 'react';
import MainBlock from "../../components/mainBlock/MainBlock";
import PrimaryBlock from "../../components/primaryBlock/PrimaryBlock";

import Layout from "../../components/layout/layout";
import Title from "../../components/heading/Title";
import Secondary from "./secondary";

import How from "./how";
import Faqs from "./faqs";



const Home: FC = () => {
  return (
      <Layout>
          <div>
              <MainBlock img={'./images/main2.png'}>
                  <Title>Сертификат электронной подписи </Title>
                  <div className={'text-secondary'}>Удостоверяющий центр выдает сертификаты открытого ключа электронной
                      подписи
                      юридическим лицам, органам государственной власти и управления, физическим лицам и
                      лицам, обладающим специальным правовым статусом.
                  </div>


              </MainBlock>
              <How/>
          </div>


          {/*<CheckCertificate/>*/}



          <PrimaryBlock title={"Что такое электронная подпись?"} image={'./images/aboutService.png'} color={'#F3F3F3'}>
              <p className={'text-main'}>Информация в электронной форме, которая присоединена или иным образом связана
                  с другой информацией в электронной форме и которая используется для определения лица, подписывающего
                  информацию.</p>
          </PrimaryBlock>
          <Secondary/>

          <Faqs/>


      </Layout>
  );
};

export default Home;