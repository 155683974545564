import React, {FC} from "react";
import Faq from "../../components/faq/Faq";

interface IFaq {
    title: string
    content: React.ReactNode
}

const faqArray: IFaq[] = [
    {
        title: 'Как проверить электронную подпись документа или сертификат?',
        content: <p>Электронную подпись документа можно проверить, загрузив документ с подписью на странице <a
            href="/verify" className={'link text-blue-700 underline'}>«Проверить подпись»</a>. Сертификат можно
            проверить в программе <a href="/downloads" className={'link text-blue-700 underline'}>«IDC: Управление
                ключами»</a>.
        </p>
        ,
    },
    {
        title: 'Как узнать срок действия сертификата?',
        content: <p>Срок действия по каждому сертификату указывается в колонке «Окончание действия» списка сертификатов
            в программе <a href="/downloads" className={'link text-blue-700 underline'}>«IDC: Управление ключами»</a>.
            Также эту информацию можно увидеть, открыв свойства сертификата. На
            вкладке «Общие» будет указан период его действия.</p>
    },
    {
        title: 'Если изменились персональные данные, можно ли внести их в существующий сертификат?',
        content: <p>Вносить какие-либо изменения в существующий сертификат невозможно. Если у держателя сертификата
            изменились персональные данные, например, произошла смена фамилии, то необходимо обратиться в Удостоверяющий
            центр. Оператор Удостоверяющего центра произведет отзыв (аннулирование) существующего сертификата и
            обновление ваших персональных данных. После посещения Удостоверяющего центра, Вы сможете запросить выпуск
            нового сертификата, с обновленными персональными данными.</p>
    },
    {
        title: 'Можно ли иметь несколько действующих сертификатов на одном ключевом носителе (токене)?',
        content: <p>Это возможно. Ключевой носитель может хранить несколько действующих сертификатов. В программе,
            реализующей функционал подписания документов при помощи электронной подписи, должен быть заложен механизм
            выбора требуемого сертификата.</p>
    },
    {
        title: 'Надежно ли хранить закрытый ключ электронной подписи на ключевом носителе (токене)?',
        content: <p>Ключевой носитель информации (токен) является одним из самых надежных способов хранения закрытого
            ключа. Закрытый ключ никогда не покидает внутреннее хранилище ключевого носителя (токена). Все операции
            подписания документов осуществляются внутри ключевого носителя (токена). Доступ к содержимому ключевого
            носителя (токена) осуществляется посредством ПИН-кода. Даже при утере или краже ключевого носителя (токена)
            посторонний человек, не зная ПИН-кода, не сможет воспользоваться его содержимым. От владельца требуется
            только одно – держать втайне от других ПИН-код.</p>
    },
    {
        title: 'Что делать при поломке или утере (краже) ключевого носителя (токена)?',
        content: <p>В этом случае Вам необходимо обратиться в Удостоверяющий центр. Оператор удостоверяющего центра
            произведет отзыв (аннулирование) всех сертификатов, которые были размещены на утерянном ключевом носителе
            (токене) и выдаст Вам новый токен. После посещения Удостоверяющего центра, Вы сможете запросить выпуск
            нового сертификата открытого ключа электронной подписи.</p>
    },
    {
        title: 'Где можно приобрести ключевой носитель (токен)?',
        content: <p>Ключевой носитель можно приобрести в Удостоверяющем центре.</p>
    },
];


const Faqs: FC = () => {
    return (
        <div className={'flex flex-col gap-6'}>
            <h3 className={'subtitle'}>Частые вопросы</h3>
            {faqArray.map(item => <Faq title={item.title} answer={item.content} key={item.title}/>)}
        </div>
    )
}

export default Faqs;