import React from 'react';
import styles from "../certificates/certificates.module.scss";
import {ReactComponent as DownloadIcon} from "../../assets/images/download.svg";
import Title from "../../components/heading/Title";
import Layout from "../../components/layout/layout";
export const LINKS = {
    ROOT_PMR_CERTIFICATE: {
        title: 'Сертификат корневого центра сертификации ПМР',
        link: "/files/ROOT-PMR-CA.cer",
        description: ''
    },

    ROOT_CERTIFICATE:{
        title:'Сертификат корневого центра сертификации',
        link: "//ca.idc.md/ext/IDC_Root_CA.crt",
        description:''
    },
    ISSUER_CERTIFICATE:{
        title:'Сертификат издающего центра сертификации',
        link:"//ca.idc.md/ext/IDC_Issuer_CA.crt",
        description:''
    },
    ROOT_CRL:{
        title:'Полный список отозванных сертификатов корневого центра сертификации',
        link:"///ca.idc.md/ext/IDC_Root_CA.crl",
        description:''
    },
    ISSUER_CRL:{
        title:'Полный список отозванных сертификатов издающего центра сертификации',
        link:"//ca.idc.md/ext/IDC_Issuer_CA.crl",
        description:'Период обновления: 3 дня'
    },
    DELTA_CRL:{
        title:'Дельта списка отозванных сертификатов издающего центра сертификации',
        link:"//ca.idc.md/ext/IDC_Issuer_CA+.crl",
        description:'Период обновления: 30 минут'
    },
    P7B:{
        title:'Скачать одним файлом',
        link:"/files/IDC_Issuer_CA.p7b",
    }
}
const Certificates = () => {
    return (
        <Layout>
            <div className={"flex justify-center items-center"}>
                <Title>Сертификаты</Title>
            </div>

            <div>
                <div className="flex justify-center  md:flex-nowrap flex-wrap gap-10">


                        <a href={LINKS.ROOT_PMR_CERTIFICATE.link} className={styles.certificate} download
                           rel="noopener">
                            {/*<span className={'text-xs text-gray-500'}>скачать</span>*/}
                            <div className={styles.wrapper}>
                                <span>скачать</span>
                                <img src="/images/111.png" alt={LINKS.ROOT_PMR_CERTIFICATE.title}/>
                            </div>

                            <span className={'font-bold'}>{LINKS.ROOT_PMR_CERTIFICATE.title}</span>
                            <div className={"absolute top-20 left-[82%] z-40"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M16 6.58569L21.4142 11.9999L16 17.4141L14.5858 15.9999L17.5858 12.9999L3 12.9999L3 10.9999L17.5858 10.9999L14.5858 7.99991L16 6.58569Z"
                                        fill="#145dea"/>
                                </svg>
                            </div>
                        </a>




                        <a href={LINKS.ROOT_CERTIFICATE.link} className={styles.certificate}>
                            {/*<span className={'text-xs text-gray-500'}>скачать</span>*/}
                            <div className={styles.wrapper}>
                                <span>скачать</span>
                            <img src="/images/222.png" alt={LINKS.ROOT_CERTIFICATE.title}/>
                            </div>
                            <span className={'font-bold'}>{LINKS.ROOT_CERTIFICATE.title}</span>
                            <div className={"absolute top-20 left-[82%] z-40"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M16 6.58569L21.4142 11.9999L16 17.4141L14.5858 15.9999L17.5858 12.9999L3 12.9999L3 10.9999L17.5858 10.9999L14.5858 7.99991L16 6.58569Z"
                                        fill="#145dea"/>
                                </svg>
                            </div>
                        </a>


                        <a href={LINKS.ISSUER_CERTIFICATE.link} className={styles.certificate}>
                            {/*<span className={'text-xs text-gray-500'}>скачать</span>*/}
                            <div className={styles.wrapper}>
                                <span>скачать</span>
                            <img src="/images/333.png" alt={LINKS.ISSUER_CERTIFICATE.title} />
                            </div>
                            <span className={'font-bold'}>{LINKS.ISSUER_CERTIFICATE.title}</span>

                        </a>



                </div>

                {/*<div className="flex justify-center  md:flex-nowrap flex-wrap gap-10">*/}


                {/*    <a href={LINKS.ROOT_CERTIFICATE.link} className={styles.certificate}>*/}
                {/*            /!*<span className={'text-xs text-gray-500'}>скачать</span>*!/*/}
                {/*            <img src="/images/44.png" alt="LINKS.ROOT_CERTIFICATE.title"  className={'h-60'}/>*/}
                {/*            <span className={'font-bold'}>{LINKS.ROOT_CERTIFICATE.title}</span>*/}

                {/*    </a>*/}


                {/*</div>*/}


                <div className="flex  md:flex-nowrap flex-wrap  justify-center mt-10 gap-10">


                    <a href={LINKS.ROOT_CRL.link} className={styles.certificate_crl}>
                        <span className={'text-xs text-gray-500'}>{LINKS.ROOT_CRL.description}</span>
                        <img src="/images/66.png" alt="LINKS.ROOT_CERTIFICATE.title" className={'h-36'}/>
                        <span className={'font-bold'}>{LINKS.ROOT_CRL.title}</span>

                    </a>
                    <a href={LINKS.ISSUER_CRL.link} className={styles.certificate_crl}>
                        <span className={'text-xs text-gray-500'}>{LINKS.ISSUER_CRL.description}</span>
                        <img src="/images/66.png" alt={LINKS.ISSUER_CRL.title} className={'h-36'}/>
                        <span className={'font-bold'}>{LINKS.ISSUER_CRL.title}</span>

                    </a>
                    <a href={LINKS.DELTA_CRL.link} className={styles.certificate_crl}>
                        <span className={'text-xs text-gray-500'}>{LINKS.DELTA_CRL.description}</span>
                        <img src="/images/66.png" alt={LINKS.DELTA_CRL.title} className={'h-36'}/>
                        <span className={'font-bold'}>{LINKS.DELTA_CRL.title}</span>
                    </a>
                </div>

        </div>
        </Layout>
    );
};

export default Certificates;