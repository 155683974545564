import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import styles from './contacts.module.scss'
import {useForm} from "react-hook-form";
import {api} from "../../api/service";
import Spin from "../../components/spin/spin";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {documents} from "../documents/documents.data";
import Layout from "../../components/layout/layout";
import Title from "../../components/heading/Title";
import Popup from "reactjs-popup";

import 'reactjs-popup/dist/index.css';
const Contacts: FC<{}> = ({}) => {
    const {register, handleSubmit, formState: {errors, isValid}, reset} = useForm();
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [token, setToken] = useState('')
    const [isShow, setIsShow] = useState(false)
    const modalRef = useRef(null);


    useEffect(() => {
        setMessage('')
    }, [])
    const onSubmit = (data: any) => {
        setIsLoading(true)
        setError('')
        setMessage('')
        setIsShow(true)
        delete (data.agree)
        data['g-recaptcha-response'] = token
        api.postEmail(data)
            .then((res) => {
                setIsLoading(false)
                reset()
                setMessage((res.message))
            })
            .catch((e) => {
                setIsLoading(false)
                setError(e.message)
            })
    };

    return (
        <Layout>

            {  <Popup contentStyle={{borderRadius:'15' +
                    'px', padding:'15px'}} modal open={isShow} position="right center">

                <div className={styles.modal}>
                    <button className={styles.close} onClick={()=>{setIsShow(false)}}>
                        &times;
                    </button>
                    <div className={styles.header}> Отправка сообщения </div>
                    <div  className={styles.content}>

                        {message.length>0 && <p className={'text-green-700 text-center mb-10'}>{message}</p>}
                        {error.length>0 && <p  className={'text-red-700 text-center mb-10'}>{error}</p>}
                        {isLoading ? <Spin/>: <button className={'btn block m-auto'} onClick={()=>{setIsShow(false)}}> ок</button>}
                    </div>
                </div>
            </Popup> }
            <div className={styles.contacts}> <Title>Обратная связь</Title>
                <GoogleReCaptchaProvider reCaptchaKey="6Lfaz40mAAAAAMC4eKyP67xE0vzQ2G7W5ZRnuNxp">
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <label>
                            <p>Имя <sup>*</sup></p>
                            <input type="text" {...register("name", {required: true})}/>
                        </label>
                        <label>
                            <p>e-mail <sup>*</sup></p>
                            <input type="email" {...register("email", {
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                required: true
                            })}/>
                        </label>
                        <label>
                            <p>Телефон</p>
                            <input type="text" {...register("phone")}/>
                        </label>
                        <label>
                            <p>Сообщение <sup>*</sup></p>
                            <textarea rows={6} {...register("message", {required: true})}/>
                        </label>
                        <label className={'flex gap-2 items-baseline'}>
                            <input className={''}
                                   type='checkbox' {...register("agree", {required: true})}/>
                            <p>Я согласен с <a className={'underline underline-offset-4 text-blue-800'}
                                               href={documents[5].link}
                                               target='_blank'>обработкой персональных данных</a></p>
                        </label>
                        <GoogleReCaptcha refreshReCaptcha={1000} onVerify={token => setToken(token)}/>
                        <button disabled={isLoading} className={'btn'}>Отправить</button>
                    </form>
                </GoogleReCaptchaProvider>


            </div>


        </Layout>
    )
        ;
};

export default Contacts;