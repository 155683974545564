import React from 'react';
import Layout from "../../components/layout/layout";
import styles from './page404.module.scss'

const Page404 = () => {
    return (
        <Layout>
            <div className={styles.error}>
                <h2 >ошибка 404</h2>
                <h3>страница не найдена</h3>
            </div>

        </Layout>
    );
};

export default Page404;