import React from 'react';
import Layout from "../../components/layout/layout";
import Title from "../../components/heading/Title";

const Tariffs = () => {
    return (
        <Layout>
            <div className={'flex text-center justify-center mb-10'}>
                <Title>секретная страница</Title>
            </div>
            <img src="https://cdn-icons-png.flaticon.com/512/8146/8146238.png" alt="" width={256} className='m-auto'/>
            <div className={'block-shadow text-xl  p-4 bg-white rounded-xl flex flex-col gap-8'}>
                <a href='/files/WinFormsApp.exe'>секретный файл</a>

            </div>
        </Layout>
    );
};

export default Tariffs;