import {FC, useState} from 'react';
import {ReactComponent as Phone} from "../../../assets/images/phone.svg";
import {ReactComponent as Mail} from "../../../assets/images/mail.svg";
import {NavLink} from "react-router-dom";
import classNames from "classnames";
import styles from "../menu/Menu.module.scss";
import {RouteNames} from "../../../common/variables/RouteNames";


const Contacts: FC = () => {
    const [isShow, setIsShow] = useState(false)
    return (
        <div className={'flex flex-col md:flex-row md:items-center md:gap-8 text-sm text-gray-700'}>
            <NavLink to={RouteNames.CONTACTS}>
                {/*<button className={'btn py-2 text-md font-bold flex gap-3 items-center '}>*/}

                <button className={'btn py-1 text-lg font-bold flex gap-3 items-center '}
                        onClick={() => setIsShow(!isShow)}>
                    <span className={'mt-[-5px]'}>@</span> <span>Обратная связь</span>
                </button>
            </NavLink>



        </div>
    );
};

export default Contacts;