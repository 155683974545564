export enum RouteNames {
    HOME = "/",
    DOCUMENTS = "/documents",
    DOWNLOADS = "/downloads",
    CONTACTS = "/contacts",
    CERTIFICATE = '/certificate',
    CERTIFICATES = '/certificates',
    CERTIFICATES_ROOT = '/certificates/root',
    CERTIFICATES_REVOKED = '/certificates/revoked',
    POLICES = '/pki/polices.html',
    TIME = '/timestamp',
    TARIFFS = '/tariffs',
    PDF = '/verify',
    WIN='/win'
}