import {RouteNames} from "../../../common/variables/RouteNames";

export interface IMenu {
  link: string
  title: string
}

export interface IMenus {
    link?: string
    items?: IMenu[]
    title: string
}

export const headerMenu: IMenus[] =
  [
    // {
    //   link: RouteNames.HOME,
    //   title: 'Главная',
    // },
    {
      link: RouteNames.DOCUMENTS,
      title: 'Документы',
    },
    {
      link: RouteNames.DOWNLOADS,
      title: 'Программы',
    },
      {
          title: "Сертификаты",
          items: [{
              link: RouteNames.CERTIFICATES_ROOT, title: 'Корневой и промежуточные сертификаты'
          }, {
              link: RouteNames.CERTIFICATES_REVOKED, title: 'Списки отзывов'
          }, {
              link: RouteNames.POLICES, title: 'Политики выдачи'
          }, {
              link: RouteNames.CERTIFICATE,
              title: 'Проверить сертификат',
          },]
      },
      {
          link: RouteNames.TIME,
          title: 'Штамп времени',
      },      {
      link: RouteNames.PDF,
      title: 'Проверить подпись',
  },
      {
          link: RouteNames.TARIFFS,
          title: 'Тарифы',
      },


  ]