import axios from "axios";

export default class ApiService {
  
 _apiBaseUrl = '//ca.idc.md';
 //  _apiBaseUrl = 'http://localhost:8000';
  headers = ():any => ({
    "Content-Type": "application/json",
    "Accept": "application/json",
  
  });

    headersPost = ():any => ({
        "Content-Type": "application/x-www-form-urlencoded",
    });

    getResource = async (url: string) => {
    const res = await fetch(`${this._apiBaseUrl}${url}`, {
      headers: this.headers(),
      method: "GET",
    });

    if (!res.ok) {
      // @ts-ignore
      if (res.headers['X-Message']) {
        // @ts-ignore
        throw new Error(res.headers['X-Message'])
      }
      else {
        throw new Error(`Could not fetch resource: ${url} - status: ${res.status}`)
      }}
    
    return await res.json();
  };

    postResource = async (url: string, data:any) => {
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('phone', data.phone)
        formData.append('email', data.email)
        formData.append('message', data.message)
        const res = await fetch(`${this._apiBaseUrl}${url}`, {
            headers: this.headersPost(),
            method: "POST",
            body:data
        });



        return await res.json();
    };
  getCertificateInfo = async (SubjectKeyIdentifier: string): Promise<ICertificateInfo> => {
    const response = await api.getResource(`/getCertificateInfo.php?id=${SubjectKeyIdentifier}`);
    return response
  };
  
 
  downloadSertificate = async (certificateSerialNumber: string): Promise<ICertificateBase64> => {
    const response =  await api.getResource(`/download.php?serial==${certificateSerialNumber}`);
    return response
  };

  postEmail = async (data:any): Promise<any>=>{
      const response = await axios.post(`${this._apiBaseUrl}/feedback.php`, data, {headers:{"Content-Type":"multipart/form-data"}})
      return response.data
  }

    // postPDF =  (data: any):Promise<any> => {
    //
    //        const d = fetch(`${this._apiBaseUrl}/checkDoc.php`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             },
    //             body: data
    //         }).then(res=>console.log(res)).catch(er=>console.log(er));
    //        return d;
    //
    // }

    postPDF = async (data:any): Promise<any>=> {
        const response = await axios.post(`${this._apiBaseUrl}/checkDoc.php`, data, {headers:{"Content-Type":"multipart/form-data"}})
        // console.log(response)
        // @ts-ignore
        if (!response.ok) {
            // @ts-ignore
            if (response.headers['X-Message']) {
                console.log(response.headers['X-Message'])
                // @ts-ignore
                throw new Error(response.headers['X-Message'])
            }

        }

        return response.data
    }
};

export const api = new ApiService();


interface IResponse {
  "ok": boolean
  "errorMessage": null | string
}

export interface ICertificateInfo extends IResponse {
  certificateInfo: {
    "serialNumber": string
    "notBefore": string
    "notAfter": string
    "issuer": string
    "commonName": string
    "certificateStatusText": string
  }
}

interface ICertificateBase64 extends IResponse {
  certificateBase64: string
}
