import React, { FC, useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Menu.module.scss';
import { headerMenu, IMenu } from './menu.data';

const DropdownMenu: FC<{ menus: IMenu[]; title: string }> = ({ menus, title }) => {
    const [isOpen, setIsOpen] = useState(false);
    let { pathname } = useLocation();
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };



    return (
        <div className="relative inline-block text-left">
            <button className="flex gap-2 items-center whitespace-nowrap font-bold " onClick={toggleDropdown}>
                {title}
                <span className="ml-2 w-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
            <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
            />
          </svg>
        </span>
            </button>
<div className="back">
    {isOpen && (
        <div
            ref={dropdownRef} // Привязываем ref к элементу меню
            className="absolute right-[-15px] top-8 border border-double mt-2 w-60 bg-blue-700 shadow-lg"
        >
            <ul>
                {menus.map((item) => (
                    <li key={item.link} className={styles.dropdown}>
                        <NavLink to={item.link} className={classNames(styles.inactive)} end>
                            {item.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )}
</div>

        </div>
    );
};

export default DropdownMenu;
